<template>
  <v-container fluid fill-height>
    <!-- <v-layout justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-form ref="form">
            <v-toolbar dark color="#1873d9">
              <v-toolbar-title>
                コード確認
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                prepend-icon="lock"
                name="password"
                v-model="code"
                :rules="rules.required"
                placeholder="コード"
                type="text"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="onSubmit">{{
                $t('buttons.login')
              }}</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout> -->
    <v-layout justify-center>
      <v-flex xs12 sm8 md3>
        <v-card class="elevation-12">
          <v-form ref="form" @submit.prevent="onSubmit">
            <v-card-text>
              <div style="min-height: 60px;">
                  <v-alert
                    class="alert-default"
                    transition="scale-transition"
                    type="error"
                    :value="alertError !== null"
                    >{{ alertError }}</v-alert
                  >
              </div>
              ご登録のメールアドレスお送りした多重認証ログインコードをご入力下さい。
              <v-text-field
                class="mx-2"
                autofocus
                name="password"
                v-model="code"
                :rules="rules.required"
                label="コード"
                type="text"
              ></v-text-field>
            </v-card-text>
            <v-card-text>
              <v-btn width="100%" type="submit" style="height: 50px" color="primary">{{ $t('buttons.login') }}</v-btn>
            </v-card-text>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { mapGetters, mapMutations } from 'vuex'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { handlErrorView, atou, getCopyErrorTextView } from '@/constants/functions'

export default {
  data () {
    return {
      strFrom: '',
      nextPath: '',
      // data:this.$store.user,
      email: this.$store.state.user.emailSave,
      password: '',
      code: '',
      rules: {
        required: [v => !!v || '必須項目です。']
      }
    }
  },
  created () {
    const routerCurrent = this.$router.currentRoute
    if (routerCurrent.params && routerCurrent.params.strFrom) {
      this.strFrom = routerCurrent.params.strFrom
    }
    if (routerCurrent.params && routerCurrent.params.nextPath) {
      this.nextPath = routerCurrent.params.nextPath
    }
  },
  watch: {
    doneTodosCount (value) {
      if (value !== null) {
        setTimeout(() => {
          this.setAlertSuccess(null)
        }, 2000)
      }
    },
    doneTodosCountError (value) {
      if (value !== null) {
        setTimeout(() => {
          this.setAlertError(null)
        }, 2000)
      }
    }
  },
  computed: {
    ...mapGetters(['alertSuccess', 'alertError', 'errorDialog', 'loadingOverlay']),
    doneTodosCount () {
      return this.alertSuccess
    },
    doneTodosCountError () {
      return this.alertError
    }
  },
  methods: {
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setPermissionUser',
      'setRoleAdminUser',
      'setAlertError'
    ]),
    // change password
    async onSubmit () {
      if (this.$refs.form.validate()) {
        const formData = {
          code: this.code,
          token: this.$store.state.user.tokenSave
        }
        this.setPermissionUser({})
        this.setRoleAdminUser(false)
        if (await this.login(formData)) {
          if (this.strFrom.length > 0) {
            this.$router.push({ path: atou(this.strFrom) })
          } else if (this.nextPath.length > 0) {
            this.$router.push({ path: atou(this.nextPath) })
          } else {
            this.$router.push({ path: '/' })
          }
        }
      }
    },

    async login (formData) {
      const query = `
            mutation login_mfa(
                $token: String!
                $code: String!
            ){
                confirmMFAStaff(
                    code: $code 
                    token: $token
                ) {
                    accessToken
                    refreshToken
                    forcePasswordChangeRequired
                    staff {
                        name
                        id
                    }
                }
            }
          `
      const variables = {
        code: formData.code.toString(),
        token: formData.token
      }
      await this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables
        })
        .then(data => {
          window.localStorage.setItem('id', data.data.confirmMFAStaff.staff.id)
          window.localStorage.setItem('name', data.data.confirmMFAStaff.staff.name)
          window.localStorage.setItem('token', data.data.confirmMFAStaff.accessToken)
          window.localStorage.setItem('refreshToken', data.data.confirmMFAStaff.refreshToken)

          // begin set permission,role user current
          const tokenLogin = data.data.confirmMFAStaff.accessToken
          if (tokenLogin) {
            const decodeTokenUser = jwt_decode(tokenLogin)
            this.setRoleAdminUser(decodeTokenUser.admin)
            if (Object.keys(decodeTokenUser).length !== 0) {
              this.setPermissionUser(decodeTokenUser.rolePermissions)
            } else {
              this.setPermissionUser({})
            }
          }
        })
        .catch(async error => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
      if (localStorage.getItem('refreshToken')) {
        return true
      } else {
        return false
      }
    },

    // confirm password
    checkPassword (value) {
      if (value.toString() === this.password.toString()) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.v-form .v-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.v-card {
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
